import * as React from "react"
import i18n from "es2015-i18n-tag";
import { Switch, Route } from "react-router";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import styled from "styled-components";
import InventoryMain from "./inventory/Main";
import { getInventoryCurrentWarehouse, getInventoryNotifications } from "../state";
import { connect } from "react-redux";
import { stockSetWarehouse, stockInit, stockDismissError } from "../state/action";
import { Notification } from "../state/types";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import Relocation from "./relocation/Relocation";
import Label from "./label/LabelView";
import Inquiry from "./inquiry/InquiryView";
import ProductLabel from "./productlabel/ProductLabel";
import { ModuleProps } from "../../config";
import { getIsBusy, getModuleNotifications, StockState } from "../state/reducer";

export interface Props extends ModuleProps
{
}

const ViewBase = styled.div`
    box-sizing: border-box;
    position: relative;
    margin: 16px;
`;

const mapStateToProps = (state: StockState) => ({
    warehouse: getInventoryCurrentWarehouse(state),
    notifications: getInventoryNotifications(state),
    mnotifications: getModuleNotifications(state),
    busy: getIsBusy(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentWarehouse: (warehouse: string) => dispatch(stockSetWarehouse(warehouse)),
    dismissError: (id: string) => dispatch(stockDismissError(id)),
    init: () => dispatch(stockInit())
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface CustomSnackBarContentProps
{
    message: string;
    icon: React.ComponentType<any>;
    style: React.CSSProperties;
}

const snackbarStyles = 
{
    success: {
        style: { backgroundColor: green[600] },
        icon: CheckCircleIcon
    }, 
    warning: {
        style: { backgroundColor: amber[700] },
        icon: WarningIcon
    },
    error: {
        style: { backgroundColor: red[600] },
        icon: ErrorIcon
    }
}

const CustomSnackBarContent = ({message, icon: Icon, style}: CustomSnackBarContentProps) => (
    <SnackbarContent style={style} message={
        <span style={{display: "flex", alignItems: "center"}}>
            <Icon style={{marginRight: 8, fontSize: 20}}/> {message}
        </span>
    } />
);

class StockManagement extends React.Component<Props & StateProps>
{
    private navigateToView = (relativePath: string) => 
        this.props.history.push(`${this.props.match.path}/${relativePath}`);

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => 
        this.props.setCurrentWarehouse(e.target.value);

    componentWillMount()
    {
        this.props.init();
    }

    componentWillReceiveProps(nextProps: Props & StateProps)
    {
        this.props.isBusy(nextProps.busy);
    }

    private renderView() 
    {
        const { warehouse = "" } = this.props;
        return (
            <>
                <Grid container spacing={8} alignItems="flex-end" style={{marginBottom: 32}}>
                    <Grid item xs={12}>
                        <TextField 
                            select fullWidth 
                            value={warehouse}
                            name="warehouse"
                            onChange={this.onChange}
                            label={i18n`Warehouse`}>
                            <MenuItem value="" disabled/>
                            <MenuItem value="001">Hasselager (001)</MenuItem>
                            <MenuItem value="002">USA (002)</MenuItem>
                            <MenuItem value="450">Dutch Warehouse(450)</MenuItem>
                            <MenuItem value="451">Holland Montage projecten (451)</MenuItem>
                            <MenuItem value="452">Holland Homecare (452)</MenuItem>
                            <MenuItem value="453">Holland Magazijn Westzaan (453)</MenuItem>
                            <MenuItem value="454">Holland Maickel (454)</MenuItem>
                            <MenuItem value="455">Holland Rene (455)</MenuItem>
                            <MenuItem value="456">Holland Johannes Ypenga (456)</MenuItem>
                            <MenuItem value="457">Holland Rob (457)</MenuItem>
                            <MenuItem value="458">Holland Harry (458)</MenuItem>
                            <MenuItem value="459">Holland David Rens (459)</MenuItem>
                            <MenuItem value="460">Holland Aleid (460)</MenuItem>
                            <MenuItem value="461">Holland Erwin (461)</MenuItem>
                            <MenuItem value="462">Holland Joryan (462)</MenuItem>
                            <MenuItem value="463">Holland Bradley Knol (463)</MenuItem>
                            <MenuItem value="464">Holland Peter Bos (464)</MenuItem>
                            <MenuItem value="465">Holland Hans and Freddy (465)</MenuItem>
                            <MenuItem value="466">Holland Erik van der Nieuwboer (466)</MenuItem>
                            <MenuItem value="467">Holland Arjen Altena (467)</MenuItem>
                            <MenuItem value="468">Holland Edwin Krijgsman (468)</MenuItem>
                            <MenuItem value="469">Holland Martijn Spaan (469)</MenuItem>
                            <MenuItem value="470">Holland service and maintenance (470)</MenuItem>
                            <MenuItem value="502">Henrik Caspers (502)</MenuItem>
                            <MenuItem value="715">Kenny Poulsen, Jacob Theis (715)</MenuItem>
                            <MenuItem value="717">Serena Kjerstine Kjeldsen (717)</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={8} alignItems="baseline" style={{marginTop: 16}}>
                    <Grid item>
                        <Button size="large" fullWidth variant="outlined" 
                            disabled={warehouse === ""}
                            onClick={() => this.navigateToView(`inventory/${warehouse}`)}>
                            {i18n`Inventory`}
                        </Button>
                    </Grid>
                </Grid> */}
                {this.renderNavigationButtons(false)}
            </>
        );
    }

    private renderNavigationButtons(disabled: boolean)
    {
        const { warehouse = "" } = this.props;
        const buttons = [
            { route: "inventory", title: i18n`Inventory`, role: "mobility.stock.inventory" },
            { route: "relocation", title: i18n`Relocation`, role: "mobility.stock.relocate" },
            { route: "label", title: i18n`Label Print`, role: "mobility.stock.label" },
            { route: "inquiry", title: i18n`Inquiry`, role: null },
            { route: "productlabel", title: i18n`Product Label`, role:null}
        ].filter(item => item.role === null || this.props.hasRole(item.role));


        return (
            <Grid container spacing={8} style={{flexGrow: 1}}>
            {
                buttons.map(button => (
                    <Grid item xs={6} key={button.route}>
                        <Button size="large" fullWidth variant="outlined" disabled={disabled}
                                onClick={() => this.navigateToView(`${warehouse}/${button.route}`)}>
                            {button.title}
                        </Button>
                    </Grid>
                ))
            }
            </Grid>
        );
    }

    private renderSnackbar = (error: Notification, index: number) =>
    {
        const { message, severity, id } = error;
        return (
            <Snackbar open={index === 0} key={id} 
                autoHideDuration={index === 0 ? 5000 : undefined}
                onClose={() => this.props.dismissError(id)}>
                <CustomSnackBarContent message={message} icon={snackbarStyles[severity].icon} style={snackbarStyles[severity].style} />
            </Snackbar>
        );
    }

    render()
    {
        const { match, warehouse, notifications, mnotifications } = this.props;
        if(warehouse === undefined)
        {
            return (
                <ViewBase>
                    {this.renderView()}
                </ViewBase>
            );
        }

        return (
            <ViewBase>
                <Switch>
                    <Route path={match.path + "/:warehouse/inventory"} component={InventoryMain} />
                    <Route path={match.path + "/:warehouse/relocation"} component={Relocation} />
                    <Route path={match.path + "/:warehouse/label"} component={Label} />
                    <Route path={match.path + "/:warehouse/inquiry"} component={Inquiry} /> 
                    <Route path={match.path + "/:warehouse/productlabel"} component={ProductLabel} /> 
                    <Route>
                        {this.renderView()}
                    </Route>
                </Switch>
                {
                    [...notifications, ...mnotifications].map(this.renderSnackbar)
                }
            </ViewBase>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockManagement);